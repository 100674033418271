'use strict';

angular.module('cpformplastApp')
  .controller('NumpadController', function ($scope) {

  	$scope.appendNumber = function(number){
      var stringNum = $scope.number.toString() + number.toString();
      $scope.number = parseInt(stringNum);
  	};

    $scope.delete = function(){
      var stringNum = $scope.number.toString();
      if (stringNum.length > 1) {
        $scope.number = parseInt(stringNum.substring(0, stringNum.length - 1));
      } else {
        $scope.number = 0;
      }
  	};

  });
